import { ROLE_NAME } from "constant";

export const variantMapping = {
	Pending: "yellow",
	Positive: "green",
	Negative: "red",
};

export const deupeDetailsIntialValues = {
	dedupResult: "",
	dedupRemarks: "",
	markAsExistingCustomer: false,
};

export const DEDUPE_RESULTS = [
	{
		label: "Positive",
		value: "Positive",
	},
	{
		label: "Negative",
		value: "Negative",
	},
];

export const STATUS_FOR_DEDUPE = [
	{
		label: "All Statuses",
		value: "All Statuses",
	},
	{
		label: "Pending",
		value: "Pending",
	},
	{
		label: "Verified",
		value: "Verified",
	},
];

export const RESULTS_FOR_DEDUPE = [
	{
		label: "All Results",
		value: "All Results",
	},
	{
		label: "Positive",
		value: "Positive",
	},
	{
		label: "Negative",
		value: "Negative",
	},
	{
		label: "Pending",
		value: "Pending",
	},
];

export const DEDUPE_TYPE_MAPPING = {
	ApplicantToCustomer: "Customer",
	ApplicantToComaker: "Customer",
	ApplicantToSpouse: "Customer",

	SpouseToCustomer: "Spouse",
	SpouseToComaker: "Spouse",
	SpouseToSpouse: "Spouse",

	Comaker1ToCustomer: "First Comaker",
	Comaker1ToSpouse: "First Comaker",
	Comaker1ToComaker: "First Comaker",

	Comaker2ToCustomer: "Second Comaker",
	Comaker2ToSpouse: "Second Comaker",
	Comaker2ToComaker: "Second Comaker",
};

export const DEDUPE_MATCH_FROM_FOR_CUSTOMER = [
	"ApplicantToCustomer",
	"ApplicantToComaker",
	"ApplicantToSpouse",
];

export const DEDUPE_MATCH_FROM_FOR_SPOUSE = [
	"SpouseToCustomer",
	"SpouseToComaker",
	"SpouseToSpouse",
];

export const DEDUPE_MATCH_FROM_FOR_FIRST_COMAKER = [
	"Comaker1ToCustomer",
	"Comaker1ToSpouse",
	"Comaker1ToComaker",
];

export const DEDUPE_MATCH_FROM_FOR_SECOND_COMAKER = [
	"Comaker2ToCustomer",
	"Comaker2ToSpouse",
	"Comaker2ToComaker",
];

export const allowedDedupeViewRoles = [
	ROLE_NAME.BOOK_KEEPER,
	ROLE_NAME.SALES_MANAGER, // Sales Manager should have BK access as well
	ROLE_NAME.COLLECTOR,
	ROLE_NAME.COLLECTION_MANAGER,
	ROLE_NAME.COLLECTOR_HEAD,
	ROLE_NAME.EVALUATOR,
	ROLE_NAME.GENERAL_MANAGER_2, // General Manager 2 should have EV access as well
	ROLE_NAME.BRANCH_MANAGER,
	ROLE_NAME.GENERAL_MANAGER, // General Manager should have BM access as well
	ROLE_NAME.CREDIT_BUREAU_ANALYST,
	ROLE_NAME.CREDIT_BUREAU_HEAD,
	ROLE_NAME.CREDIT_BUREAU,
];

export const allowedDedupeModifyRoles = [ROLE_NAME.CREDIT_BUREAU_ANALYST];

export const dedupeStepLabels = [
	"Customer",
	"Spouse",
	"Comaker 1",
	"Comaker 2",
	"Summary",
];
